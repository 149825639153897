import React, { ReactElement, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from '../components/common';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ContentHero from '../components/common/contentSections/ContentHero';
import BannerSection from '../components/common/contentSections/BannerSection';
import styled from '@emotion/styled';
import { AnchorHTMLAttributes } from 'react';
import { IPost, ITag } from '../utils/entities';

export const Excerpt = styled(Card.Text)`
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IBlog {
  data: {
    allGhostPost: {
      edges: {
        node: IPost;
      }[];
    };
    allInternalTags: {
      edges: {
        node: ITag;
      }[];
    };
    allPublicTags: {
      edges: {
        node: ITag;
      }[];
    };
  };
  location: {
    pathname: string;
  };
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    humanPageNumber: number;
    numberOfPages: number;
  };
}

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

interface ActiveLink extends AnchorHTMLAttributes<HTMLAnchorElement> {
    active: boolean;
}

export const ListItem = styled.a < ActiveLink > `
    cursor: pointer;
    padding: 1rem 2rem;
    display: flex;
    text-align: center;
    height: 100%;
    align-items: center;
    color: #4d4d4d;
    text-decoration: none;
    border-bottom: ${props => (props.active ? `5px solid #cd3535` : ``)};
    :hover {
        text-decoration: none;
        color: #4d4d4d;
    }
`;

const Blog = ({ data }: IBlog): ReactElement => {
    const posts = data.allGhostPost.edges;
    const tags = data.allPublicTags.edges;
    const [tagFilter, setTagFilter] = useState(`design`);
    const handleTagFilterChange = (tag: string) => {
        setTagFilter(tag);
    };

    return (
        <>
            <Layout>
                <Container>
                    <ContentHero
                        heading="Blogs"
                        desc="Customers can view their dashboard capturing who they are, settings, who has access and what kind of information they have access to."
                        imageUrl="/images/blogs-hero.svg"
                    />
                    <Row style={{ padding: `1rem` }} noGutters>
                        
                        <Col
                            lg="12"
                            style={{ display: `flex`, alignItems: `center` }}
                        >
                            {tags.map(
                                ({ node }, i) => [
                                    `design`,
                                    `photography`,
                                    `graphic`,
                                    `video`,
                                    `technology`,
                                    `trends`,
                                ].includes(node.slug) && (
                                    <ListItem
                                        key={i}
                                        active={tagFilter === node.slug}
                                        onClick={() => handleTagFilterChange(node.slug)
                                        }
                                    >
                                        <span>
                                            {node.name.toUpperCase()}
                                        </span>
                                    </ListItem>
                                ),
                            )}
                        </Col>
                        {posts
                            .filter(({ node }) => node.tags.find(item => item.slug === `${tagFilter}`))
                            .map((post, i) => {
                                const url = `/blog/${post.node.slug}/`;

                                return (
                                    <Col key={i} md="12" lg="4">
                                        <LinkWrapper to={url}>
                                            <Card
                                                style={{
                                                    color: `#535b6c`,
                                                    border: `none`,
                                                    margin: `1rem`,
                                                    boxShadow: `0 15px 40px 0 rgba(201, 201, 201, 0.24)`,
                                                }}
                                            >
                                                <Card.Body
                                                    style={{
                                                        textAlign: `center`,
                                                        height: `auto`,
                                                    }}
                                                >
                                                    <Card.Img
                                                        style={{
                                                            marginTop: `2rem`,
                                                            marginBottom: `2rem`,
                                                        }}
                                                        src={
                                                            post.node.feature_image !== null
                                                                ? post.node.feature_image
                                                                : `/images/writing-posts-with-ghost.png`
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            fontSize: `1.05rem`,
                                                            marginBottom: `1rem`,
                                                            fontWeight: `bold`,
                                                        }}
                                                    >
                                                        {post.node.title}
                                                    </div>
                                                    <Excerpt
                                                        style={{
                                                            
                                                            display: `-webkit-box`,
                                                            overflow: `hidden`,
                                                            textOverflow: `ellipsis`,
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: `vertical`,
                                                        }}
                                                    >
                                                        {post.node.excerpt}
                                                    </Excerpt>
                                                </Card.Body>
                                            </Card>
                                        </LinkWrapper>
                                    </Col>
                                );
                            })}
                        <Col md="12" lg="4"></Col>
                    </Row>
                    <BannerSection />
                </Container>
            </Layout>
        </>
    );
};

export default Blog;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query GhostPostQuery($limit: Int!, $skip: Int!) {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
        allInternalTags: allGhostTag(
            filter: { visibility: { eq: "internal" } }
        ) {
            edges {
                node {
                    ...GhostTagFields
                }
            }
        }
        allPublicTags: allGhostTag(filter: { visibility: { eq: "public" } }) {
            edges {
                node {
                    ...GhostTagFields
                }
            }
        }
    }
`;
